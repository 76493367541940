// src/theme.js
export const lightTheme = {
  body: '#FFF',
  text: '#333',
  buttonBackground: '#007BFF',
  headerBackground: 'linear-gradient(135deg, #F0F9FF, #A6C1EE)',
  defaultBackground: 'linear-gradient(135deg, #fff0f0, #e8feff)',
  airportsBackground: 'linear-gradient(135deg, #ed6ea0, #ec8c69)',
  contactBackground: 'linear-gradient(135deg, #ed6ea0, #ec8c69)',
  sceneriesBackground:'linear-gradient(135deg, #84fab0, #8fd3f4)',
  projectCard: 'white',
  titleText: '#1a475f',
  sectionTitle: '#1a475f',
  sectionSubTitle: '#5f1a47',
  tagBackground: '#c6edf7',
  skillsCard: 'white',
  iconColor: '#333',
  iconHoverColor: '#ae4e38',
  headerImage: "url('/images/santa-light.jpg')",
  headerShadow: '0px 0px 10px rgba(255, 255, 255, 0)',
  linkColor: '#1a73e8',
  linkHoverColor: '#0c47a1',
  santaBackground: '#dfebeb',
  liveTitle: 'white',
  logoImage: '/images/logo-light.png',
};

export const darkTheme = {
  body: '#121212',
  text: '#FAFAFA',
  buttonBackground: '#444',
  headerBackground: 'linear-gradient(135deg, #1e1e1e, #252525)',
  defaultBackground: 'linear-gradient(135deg, #0d0d0d, #1d1d1d)',
  airportsBackground: 'linear-gradient(135deg, #200122, #6f0000)',
  contactBackground: 'linear-gradient(135deg, #C04848, #480048)',
  sceneriesBackground:'linear-gradient(135deg, #0f3443, #0d7249)',
  projectCard: '#303030',
  titleText: '#43c6e7',
  sectionTitle: '#43c6e7',
  sectionSubTitle: '#A2ECE6',
  tagBackground: '#121e26',
  skillsCard: '#333',
  iconColor: '#ddd',
  iconHoverColor: '#A2ECE6',
  headerImage: "url('/images/santa-dark.jpg')",
  headerShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)',
  linkColor: '#8ab4f8',
  linkHoverColor: '#6a8cfc',
  santaBackground: '#1a475f',
  liveTitle: '#43c6e7',
  logoImage: '/images/logo.png',
};
